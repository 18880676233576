@import 'variables';

.aspect {
  height: 0;
  overflow: hidden;

  > img, > picture img {
    width: 100%;
    height: auto;
  }
}

.aspect-shirt {
  &:extend(.aspect);
  padding-bottom: 150%;
}

.aspect-xs-1-1 {
  &:extend(.aspect);
  padding-bottom: 100%;
}

.aspect-xs-5-4 {
  &:extend(.aspect);
  padding-bottom: 80%;
}

.aspect-xs-48-17 {
  &:extend(.aspect);
  padding-bottom: 35.416666666666%;
}

.aspect-xs-4-5 {
  &:extend(.aspect);
  padding-bottom: 125%;
}

.aspect-xs-4-3 {
  &:extend(.aspect);
  padding-bottom: 75%;
}

.aspect-xs-16-9 {
  .aspect();
  padding-bottom: 56.25%;
}

.aspect-xs-21-9 {
  &:extend(.aspect);
  padding-bottom: 42.85%;
}

.aspect-xs-125-34 {
  &:extend(.aspect);
  padding-bottom: 27.2%;
}

.aspect-xs-96-25 {
  &:extend(.aspect);
  padding-bottom: 26%;
}

@media (@min-screen-xs) {
  .aspect-sm-1-1 {
    &:extend(.aspect);
    padding-bottom: 100%;
  }

  .aspect-sm-5-4 {
    &:extend(.aspect);
    padding-bottom: 80%;
  }

  .aspect-sm-48-17 {
    &:extend(.aspect);
    padding-bottom: 35.416666666666%;
  }

  .aspect-sm-4-5 {
    &:extend(.aspect);
    padding-bottom: 125%;
  }

  .aspect-sm-4-3 {
    &:extend(.aspect);
    padding-bottom: 75%;
  }

  .aspect-sm-16-9 {
    &:extend(.aspect);
    padding-bottom: 56.25%;
  }

  .aspect-sm-21-9 {
    &:extend(.aspect);
    padding-bottom: 42.85%;
  }

  .aspect-sm-125-34 {
    &:extend(.aspect);
    padding-bottom: 27.2%;
  }

  .aspect-sm-96-25 {
    &:extend(.aspect);
    padding-bottom: 26%;
  }
}

@media (@min-screen-sm) {
  .aspect-md-1-1 {
    &:extend(.aspect);
    padding-bottom: 100%;
  }

  .aspect-md-5-4 {
    &:extend(.aspect);
    padding-bottom: 80%;
  }

  .aspect-md-48-17 {
    &:extend(.aspect);
    padding-bottom: 35.416666666666%;
  }

  .aspect-md-4-5 {
    &:extend(.aspect);
    padding-bottom: 125%;
  }

  .aspect-md-4-3 {
    &:extend(.aspect);
    padding-bottom: 75%;
  }

  .aspect-md-16-9 {
    &:extend(.aspect);
    padding-bottom: 56.25%;
  }

  .aspect-md-21-9 {
    &:extend(.aspect);
    padding-bottom: 42.85%;
  }

  .aspect-md-125-34 {
    &:extend(.aspect);
    padding-bottom: 27.2%;
  }

  .aspect-md-96-25 {
    &:extend(.aspect);
    padding-bottom: 26%;
  }
}

@media (@min-screen-md) {
  .aspect-lg-1-1 {
    &:extend(.aspect);
    padding-bottom: 100%;
  }

  .aspect-lg-5-4 {
    &:extend(.aspect);
    padding-bottom: 80%;
  }

  .aspect-lg-48-17 {
    &:extend(.aspect);
    padding-bottom: 35.4166666666667%;
  }

  .aspect-lg-4-5 {
    &:extend(.aspect);
    padding-bottom: 125%;
  }

  .aspect-lg-4-3 {
    &:extend(.aspect);
    padding-bottom: 75%;
  }

  .aspect-lg-16-9 {
    &:extend(.aspect);
    padding-bottom: 56.25%;
  }

  .aspect-lg-21-9 {
    &:extend(.aspect);
    padding-bottom: 42.85%;
  }

  .aspect-lg-125-34 {
    &:extend(.aspect);
    padding-bottom: 27.2%;
  }

  .aspect-lg-96-25 {
    &:extend(.aspect);
    padding-bottom: 26%;
  }
}