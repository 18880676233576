@import '../../../../submodules/src/styles/aspect';
@import '../element/_mixins';
@import "../element/_icons-mixins";
@import '../element/_catalog-variables';

.icon-sort();
.icon-filter();

#catalog-overlay {
  .base-overlay();
}

#catalog-controls-info {
  justify-content: center;

  @media (@min-screen-md) {
    justify-content: space-between;

    @media (@min-screen-md) {
      .catalog-desktop-sort-container:nth-child(2) {
        margin-left: auto;
      }
    }
  }
}

.catalog-filter-group-btn {
  transition: background-color @transition-default, color @transition-default;
  background-color: white;

  &.active {
    background-color: @color-default-4;
    color: white;
  }

  &:hover {
    background-color: @color-primary-5;
    color: white;
  }
}

#catalog-mobile-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: calc(@header-z-index - 1);
  width: 100vw;
  line-height: @catalog-mobile-toolbar-height;
  text-align: center;
  background-color: @color-default-1;
  pointer-events: initial;
}

#catalog-mobile-sort {
  list-style: none;
  padding-left: 0;
  position: fixed;
  left: 0;
  bottom: @catalog-mobile-toolbar-height;
  width: 100vw;
  transform: translateY(100%);
  background-color: white;
  text-align: center;
  box-shadow: @default-shadow;
  transition: transform @transition-default;
  pointer-events: initial;

  &.show {
    transform: none;
  }

  li {
    line-height: 3rem;
    border-bottom: 1px solid @color-default-2;

    &:first-child {
      text-transform: uppercase;
      background-color: @color-default-1;
    }
  }
}

#catalog-mobile-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: @header-z-index + 1;
  pointer-events: none;

  #catalog-mobile-filter-header,
  #catalog-mobile-filter-body {
    position: absolute;
    top: 0;
    right: -100%;
    width: 80vw;
    background-color: white;
    transition: right @drawer-transition-duration @transition-curve;
  }
}

@media (@max-screen-lg) {
  .catalog-filter-check {
    background-color: @color-default-1;
    border-radius: @radius-default;
    padding: .25rem;

    > div {
      padding: 0 .5rem;
    }
  }
}

/** ---- QUICK FILTERS ---- **/

.catalog-quick-filters-list {
  justify-content: center;
  margin: 0 -.5rem -.5rem;

  .catalog-quick-filter {
    margin: 0 .5rem .5rem;
  }
}

.catalog-quick-filter {
  background-color: @color-default-1;
  transition: color @transition-default, background-color @transition-default;

  &:hover {
    background-color: @color-primary-3;
    color: @color-white;
    text-decoration: none;

    svg {
      fill: @color-white;
    }
  }

  svg {
    display: none;
    height: 2rem;
    transition: fill @transition-default;
  }

  &.selected svg {
    fill: white;
  }

  @media (@min-screen-sm) {

    svg {
      display: block;
    }
  }
}